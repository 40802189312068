<template>
  <div class="banner">
    <div class="banner-inner">
      <h1 class="banner-title">Share What You Love to Earn More of It</h1>
      <a
        target="_blank"
        href="https://market-live.refersion.com/affiliate/registration"
        class="affiliate-button"
      >
        Apply to Become an Affiliate
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AffiliateBanner',
  };
</script>

<style lang="scss" scoped>
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 90vh;
    max-height: 800px;
    background: url('../assets/pic-banner.jpg'),
      linear-gradient(90deg, #fafafa 50%, #dddddb 50%);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 576px) {
      background-position: 60% 50%;
    }

    &-title {
      margin: 0 auto 20px;
      text-align: center;
      color: #2f3b45;
      font-size: 30px;
      line-height: 75/65 * 100%;
      font-weight: 700;
      letter-spacing: 0.03em;
      max-width: 370px;

      @media screen and (min-width: 1200px) {
        font-size: 65px;
        font-weight: 700;
        letter-spacing: 0.03em;
        max-width: 790px;
      }
    }
  }
</style>
