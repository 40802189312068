<template>
  <div class="main-content affiliate-container">
    <div class="description">
      <div class="pics">
        <img
          class="pic pic-1"
          :src="
            require('src/entities/become-affiliate/assets/pic2-745x1020-2x.jpg')
          "
          alt="picture loading"
        />
        <img
          class="pic pic-2"
          :src="
            require('src/entities/become-affiliate/assets/pic3-340x480-2x.jpg')
          "
          alt="picture loading"
        />
        <img
          class="pic pic-3"
          :src="
            require('src/entities/become-affiliate/assets/pic4-540x480_1x.jpg')
          "
          alt="picture loading"
        />
      </div>
      <div class="description-container">
        <div class="description-content">
          <h3 class="affiliate-subtitles">
            Sharing is Caring
            <span style="font-style: italic; font-weight: 500"
              >(& Earning)</span
            >
          </h3>
          <p class="affiliate-text">
            As an Affiliate, you can earn up to 20% in MARKET credit when you
            promote your favorite MARKET finds with your existing audience.
          </p>
        </div>
        <div class="description-footer" />
      </div>

      <a
        target="_blank"
        href="https://market-live.refersion.com/affiliate/registration"
        class="affiliate-button"
      >
        Apply to Become an Affiliate
      </a>
    </div>
    <div class="program">
      <div class="program-picture">
        <img
          :src="require('src/entities/become-affiliate/assets/pic-program.jpg')"
          alt="MARKET Affiliate Program"
        />
      </div>

      <div class="program-content">
        <div class="program-content--text">
          <h3 class="program-content--title affiliate-subtitles">
            MARKET Affiliate Program
          </h3>

          <p class="program-content--description affiliate-text">
            Leverage our expansive catalog products and
            your current audience to earn MARKET gift cards, then use them to
            find your next favorite thing.
          </p>
        </div>

        <a
          target="_blank"
          href="https://market-live.refersion.com/affiliate/registration"
          class="affiliate-button program-content--button"
        >
          Apply to Become an Affiliate
        </a>
      </div>
    </div>

    <div class="steps">
      <h3 class="affiliate-subtitles text-center">
        Get Started in 4 Easy Steps
      </h3>

      <ul class="steps-list">
        <li class="steps-list--item">
          <span>1</span>
          <p class="affiliate-text">
            Click
            <a
              class="affiliate-text"
              href="https://market-live.refersion.com/affiliate/registration"
            >
              this link
            </a>
            to apply to be an affiliate
          </p>
        </li>
        <li class="steps-list--item">
          <span>2</span>
          <p class="affiliate-text">Get approved</p>
        </li>
        <li class="steps-list--item">
          <span>3</span>
          <p class="affiliate-text">
            Share MARKET products using your unique link
          </p>
        </li>
        <li class="steps-list--item">
          <span>4</span>
          <p class="affiliate-text">Start earning MARKET gift cards</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AffiliateMain',
  };
</script>

<style lang="scss" scoped>
  .main-content {
    .description {
      position: relative;
      margin-top: 130px;

      @include sm() {
        margin-top: 235px;
      }

      @include md() {
        margin-top: 240px;
      }

      @media screen and (min-width: 1200px) {
        margin-top: 530px;
      }

      .affiliate-button {
        position: absolute;
        bottom: -172px;
        left: 0;
        z-index: 2;
        @include sm() {
          bottom: -200px;
        }

        @media screen and (min-width: 1200px) {
          bottom: -300px;
        }
      }

      .pics {
        position: absolute;
        right: 0;
        top: 0;

        @include md() {
          top: 70px;
        }

        @media screen and (min-width: 1200px) {
          top: 0px;
        }
      }

      .pic {
        position: absolute;

        &-1 {
          width: 270px;
          top: -70px;
          right: -20px;
          transform: translateX(30%);

          @include sm() {
            transform: none;
            width: 375px;
            top: -225px;
          }

          @include md() {
            right: -40px;
            top: -295px;
          }

          @media screen and (min-width: 1200px) {
            width: 745px;
            top: -515px;
          }
        }

        &-2 {
          z-index: 2;
          width: 135px;
          bottom: -450px;
          right: 145px;

          @include sm() {
            width: 180px;
            bottom: -510px;
            right: 310px;
          }
          @include md() {
            bottom: -430px;
          }
          @media screen and (min-width: 1200px) {
            width: 340px;
            bottom: -895px;
            right: 615px;
          }
        }

        &-3 {
          width: 214px;
          bottom: -510px;
          right: -155px;
          @media screen and (min-width: 460px) {
            right: -90px;
          }

          @include sm() {
            width: 280px;
            bottom: -565px;
            right: 10px;
          }
          @include md() {
            bottom: -490px;
          }
          @media screen and (min-width: 1200px) {
            width: 540px;
            bottom: -1015px;
            right: 30px;
          }
        }
      }

      &-container {
        border: 1px solid #2f3b45;
      }

      &-content {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 30px 15px;
        position: relative;
        z-index: 1;
        max-width: 280px;

        @include md() {
          padding: 30px 15px 60px;
        }

        @media screen and (min-width: 1200px) {
          padding: 40px;
        }

        @include md() {
          max-width: 430px;
        }

        @media screen and (min-width: 1200px) {
          max-width: 620px;
        }

        .affiliate-subtitles {
          @media screen and (max-width: 1201px) {
            max-width: 270px;
          }
        }
      }

      &-footer {
        height: 100px;
        background-color: #2f3b45;

        @media screen and (min-width: 1200px) {
          height: 290px;
        }
      }
    }

    .program {
      display: flex;
      align-items: center;
      column-gap: 50px;
      position: relative;
      height: 580px;
      margin-top: 90px;

      @include sm() {
        height: auto;
        margin-top: 270px;
      }

      @media screen and (min-width: 1200px) {
        margin-top: 400px;
      }

      &-picture {
        height: 370px;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 576px) {
          width: 206px;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-51%);
          flex: 0 0 calc(100% / 3);
        }

        @include sm() {
          flex: 0 0 calc(100% / 3);
          height: 400px;
        }

        @include md() {
          flex: 0 0 calc(100% / 2.3);

          height: 600px;
        }

        @media screen and (min-width: 1200px) {
          height: 860px;
          flex: 0 0 calc(100% / 3);
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 576px) {
          margin-top: auto;
        }

        &--text {
          background-color: rgba(255, 255, 255, 0.5);
          padding: 30px 15px;
          width: 85%;

          @media screen and (max-width: 576px) {
            margin-left: auto;
          }

          @include sm() {
            width: auto;
            padding: 0;
            background: none;
          }
        }

        &--button {
          @media screen and (max-width: 576px) {
            margin: 0 auto;
          }
        }
      }
    }

    .steps {
      max-width: 600px;
      margin: 120px auto;

      @media screen and (min-width: 1200px) {
        max-width: 900px;
      }

      &-list {
        list-style: none;
        padding: 0;
        max-width: 400px;
        margin: 0 auto;

        @media screen and (min-width: 1200px) {
          max-width: 800px;
        }

        &--item {
          display: flex;
          align-items: center;
          column-gap: 20px;

          p {
            margin-bottom: 0;

            a {
              text-decoration: underline;
            }
          }

          span {
            color: rgba(0, 0, 0, 0.22);
            font-family: serif;
            font-size: 60px;
            font-weight: bold;

            @media screen and (min-width: 1200px) {
              font-size: 90px;
            }
          }
        }
      }
    }
  }
</style>
