<template>
  <div class="affiliate-page">
    <affiliate-banner-component />
    <affiliate-main-component />
    <affiliate-faq-component />
    <affiliate-pre-footer-component />
  </div>
</template>

<script>
  import AffiliateBannerComponent from './components/affiliate-banner.component.vue';
  import AffiliateFaqComponent from './components/affiliate-faq.component.vue';
  import AffiliateMainComponent from './components/affiliate-main.component.vue';
  import AffiliatePreFooterComponent from './components/affiliate-pre-footer.component.vue';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'BecomeAffiliateContainer',
    components: {
      AffiliateBannerComponent,
      AffiliateFaqComponent,
      AffiliateMainComponent,
      AffiliatePreFooterComponent,
    },
    created() {
      $stats.send('page_opened', {
        page_type: 'become-affiliate',
      });
    }
  };
</script>

<style lang="scss">
  .affiliate {
    &-container {
      padding: 20px;
      width: 100%;
      max-width: 480px;
      margin: auto;

      @media screen and (min-width: 576px) {
        max-width: 680px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 1480px;
      }
    }

    &-button {
      border: 1px solid #2f3b45;
      padding: 8px 20px;
      color: #2f3b45;
      font-size: 16px;
      display: inline-block;
      letter-spacing: -0.01em;

      &:hover {
        color: #5090aa;
        border-color: #5090aa;
      }

      @media screen and (min-width: 1200px) {
        font-weight: 300;
        font-size: 26px;
        padding: 14px 38px;
      }
    }

    &-subtitles {
      font-weight: bold;
      color: #2f3b45;
      font-weight: 700;
      font-size: 25px;
      line-height: 1.35em;
      letter-spacing: 0.03em;
      margin-bottom: 25px;

      @media screen and (min-width: 1200px) {
        font-size: 55px;
      }
    }

    &-text {
      color: #2f3b45;
      font-size: 16px;
      line-height: 1.45em;
      @media screen and (min-width: 1200px) {
        font-size: 30px;
      }

      &:hover {
        color: #2f3b45;
      }
    }
  }
</style>
