<template>
  <div class="affiliate-faq">
    <div class="faq-picture">
      <img
        :src="require('src/entities/become-affiliate/assets/pic-FAQ.jpg')"
        alt="FAQ"
      />
    </div>

    <div class="faq-questions">
      <div
        v-for="item of items"
        :key="item.id"
        class="faq-item"
        :class="{ active: item.active }"
      >
        <div class="faq-item--title">
          <span class="affiliate-text">{{ item.title }}</span>
          <span class="expand-item" @click="handleExpandFaq(item)">
            {{ item.active ? '-' : '+' }}
          </span>
        </div>
        <div v-show="item.active" class="faq-item--text">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AffiliateFaq',

    data() {
      return {
        items: [
          {
            id: 1,
            title: 'Who can be an Affiliate?',
            text: 'Anyone! Whether you’re an influencer, blogger, or just a person with a large social reach, we want to partner with you. However, we do reserve the right to refuse any site that does not meet our quality, audience, and/or content standards.',
            active: false,
          },
          {
            id: 2,
            title:
              'Can I advertise for MARKET on my personal website and/or social pages?',
            text: 'Absolutely!',
            active: false,
          },
          {
            id: 3,
            title: 'How will I be compensated?',
            text: 'Compensation rates are determined based on your audience size. Every time you earn $25 or more in MARKET credit, we’ll email you a gift card code for that amount that can be redeemed at any time.',
            active: false,
          },
          {
            id: 4,
            title: 'How long is the application process?',
            text: 'Not long at all. We’ll get back to you within 3 business days',
            active: false,
          },
          {
            id: 5,
            title: 'What products are eligible?',
            text: 'All products available online at MARKET.live, except for Gift Cards, are eligible. When customers referred by your site make a purchase, you earn a commission.',
            active: false,
          },
          {
            id: 6,
            title: 'How does fulfillment work?',
            text: 'MARKET handles all processing, fulfillment, shipments, and customer service for these orders. Any time a customer uses your link to make a purchase on MARKET, you’ll collect the rewards',
            active: false,
          },
          {
            id: 7,
            title: 'What does it cost to become an Affiliate? ',
            text: 'Nothing. Applying to and participating in our Affiliate Program is completely free. We do not impose an application fee or a minimum sales quota.',
            active: false,
          },
        ],
      };
    },
    methods: {
      handleExpandFaq(item) {
        this.items = this.items.map((i) => {
          if (i.id === item.id) {
            return {
              ...i,
              active: !item.active,
            };
          }

          return i;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .faq {
    &-picture {
      max-width: 1440px;
      margin: 0 auto;

      img {
        width: 100%;
        display: block;
      }
    }

    &-questions {
      max-width: 650px;
      margin: 60px auto;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      @media screen and (min-width: 1200px) {
        max-width: 980px;
        row-gap: 60px;
      }
    }

    &-item {
      padding: 10px 20px;
      border: 1px solid #2f3b45;
      color: #2f3b45;

      &.active {
        background-color: rgba(80, 145, 170, 0.12);
      }

      &--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 30px;
      }

      &--text {
        margin-top: 15px;
        color: #2f3b45;
        font-size: 14px;
        line-height: 1.45em;
        @media screen and (min-width: 1200px) {
          font-size: 24px;
          margin-top: 30px;
        }
      }

      .expand-item {
        font-size: 30px;
        cursor: pointer;

        @media screen and (min-width: 1200px) {
          font-size: 60px;
        }
      }
    }
  }
</style>
