<template>
  <div class="pre-footer affiliate-container">
    <div class="pre-footer--inner">
      <div class="pre-footer--content">
        <div class="pre-footer--content--text">
          <h3 class="affiliate-subtitles">What is MARKET?</h3>
          <p class="affiliate-text">
            As the first of its kind, MARKET is a centralized eCommerce platform
            that enables brands, content creators, influencers, personalities,
            and retailers of all kinds and sizes, from anywhere in the world to
            provide a highly immersive and entertaining social shopping
            experience.
          </p>
        </div>
      </div>
      <div class="pre-footer--picture">
        <img
          :src="
            require('src/entities/become-affiliate/assets/pic-pre-footer.jpg')
          "
          alt="MARKET Affiliate Program"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AffiliatePreFooter',
  };
</script>

<style lang="scss" scoped>
  .pre-footer {
    @media screen and (max-width: 576px) {
      margin-top: -70px;
    }
    &--inner {
      display: flex;
      align-items: center;
      column-gap: 50px;
      position: relative;
      height: 580px;

      @include sm() {
        height: auto;
      }
    }

    &--picture {
      height: 370px;
      position: relative;
      overflow: hidden;

      @media screen and (max-width: 576px) {
        width: 206px;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-51%);
      }

      @include sm() {
        flex: 0 0 calc(100% / 3);
        height: 400px;
      }

      @include md() {
        flex: 0 0 calc(100% / 2.3);

        height: 600px;
      }

      @media screen and (min-width: 1200px) {
        height: 860px;
        flex: 0 0 calc(100% / 3);
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &--content {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: flex-start;
      position: relative;
      z-index: 1;

      @media screen and (max-width: 576px) {
        margin-top: auto;
      }

      &--text {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 30px 15px;
        width: 85%;

        @media screen and (max-width: 576px) {
          margin-right: auto;
        }

        @include sm() {
          width: auto;
          padding: 0;
          background: none;
        }
      }
    }
  }
</style>
